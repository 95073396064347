import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { LektoAuthService } from "src/app/shared/services/essential/lekto-auth.service";
import { environment } from "src/environments/environment";

import { NzSpinModule } from "ng-zorro-antd/spin";

@Component({
  selector: "app-hangfire",
  templateUrl: "./hangfire.component.html",
  styleUrls: ["./hangfire.component.scss"],
  standalone: true,
  imports: [NzSpinModule],
})
export class HangfireComponent implements OnInit {
  private access_token?: string;
  readonly environment = environment;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: LektoAuthService
  ) {}

  ngOnInit(): void {
    this.access_token = this.authService.getTokenData()?.access_token;
  }

  get hangfireUrl(): SafeUrl | null {
    if (this.access_token) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    return null;
  }

  private get url() {
    return `${environment.hangfireUrl}?access_token=${this.access_token}`;
  }

  redirect() {
    window.location.href = this.url;
  }
}
