<nz-spin nzSimple [nzSpinning]="!hangfireUrl"> </nz-spin>
<div class="iframe-container">
  @if (hangfireUrl) {
    <iframe
      [src]="hangfireUrl"
      frameborder="0"
      scrolling="no"
      allowfullscreen
      (load)="redirect()">
    </iframe>
  }
</div>
