import { AppComponent } from "./app/app.component";
import { bootstrapApplication } from "@angular/platform-browser";

import { appConfig } from "./app/app.config";

bootstrapApplication(AppComponent, appConfig)
  .then(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then(() => navigator.serviceWorker.register("/ngsw-worker.js"))
        .catch(console.error);
    }
  })
  .catch((err) => console.error(err));
