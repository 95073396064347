/* eslint-disable @typescript-eslint/no-explicit-any */
import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { ApolloClientOptions, InMemoryCache } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
import { environment } from "src/environments/environment";
import { createPersistedQueryLink } from "apollo-angular/persisted-queries";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import { sha256 } from "crypto-hash";

const uri = `${environment.serviceUrl}/graphql`;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const persistedLink = createPersistedQueryLink({
    sha256,
    retry: (error) => {
      const networkError = error.networkError as any;
      const retry =
        networkError?.error?.errors[0]?.message == "PersistedQueryNotFound" &&
        networkError.status == 400;

      if (!environment.production && retry) {
        // eslint-disable-next-line no-console
        console.log("[PersistedQueryNotFound] Will retry graphql query...");
      }

      return retry;
    },
  });

  const http = httpLink.create({ uri, withCredentials: false });
  const linkChain = persistedLink.concat(http);
  const cache = new InMemoryCache();

  persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  return {
    link: environment.production ? linkChain : http,
    cache: cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
