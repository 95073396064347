/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { School } from "../models/school/school";
import { SchoolService } from "../services/school/school.service";
import { tap } from "rxjs/operators";
import { RouteParamsService } from "../services/essential/route-params.service";

@Injectable({
  providedIn: "root",
})
export class SchoolEssentialsResolver {
  constructor(
    private router: Router,
    private paramsService: RouteParamsService,
    private schoolService: SchoolService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): Observable<School> {
    const idSchool = +route.params["idSchool"];
    return this.schoolService.getEssentials(idSchool).pipe(
      tap((obj: any) => {
        localStorage.setItem(
          "selectedSchool",
          JSON.stringify({
            txName: obj.txName,
            idSchool: obj.idSchool,
          })
        );
        this.paramsService.schoolData$.next(obj);
      })
    );
  }
}
