import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterOutlet,
  RoutesRecognized,
  UrlSegment,
} from "@angular/router";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { RouteParamsService } from "./shared/services/essential/route-params.service";
import { LektoAuthService } from "./shared/services/essential/lekto-auth.service";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [LoadingBarModule, RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  LOAD_BAR_INCLUDE_SPINNER = false;
  LOAD_BAR_HEIGHT = "2px";
  LOAD_BAR_COLOR = "#8c22bc";

  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date | null = null;
  countdown?: number | null = null;

  routeSegments: string[] = [];
  logoutTimeout?: ReturnType<typeof setTimeout>;
  mutationObserver?: MutationObserver;

  constructor(
    private paramsService: RouteParamsService,
    private router: Router,
    private lektoAuthService: LektoAuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    private ngZone: NgZone
  ) {
    this.startIdleWatcher();
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        if (!val.state.url.toLocaleLowerCase().startsWith("/school/")) {
          return;
        }

        let moveNext = true;
        let lastChild = val.state.root.firstChild;

        while (moveNext) {
          if (lastChild?.params.idSchool) {
            const idSchool = lastChild.params.idSchool;
            this.paramsService.idSchool$.next(idSchool);
            moveNext = false;
            continue;
          }

          lastChild = lastChild!.firstChild!;
        }
      }
    });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        const currentRoot = this.router.routerState.snapshot.root;
        this.routeSegments = this.buildSegments(currentRoot);
        this.paramsService.routerSegments$.next(this.routeSegments);
      });

    if (this.lektoAuthService.isLoggedIn()) {
      const expires = this.lektoAuthService.getExpiresInMilliseconds();
      // eslint-disable-next-line no-console
      console.log("Auto logout in", expires, "milliseconds");

      if (this.logoutTimeout) {
        clearTimeout(this.logoutTimeout);
      }

      this.logoutTimeout = setTimeout(() => {
        this.lektoAuthService.logout(this.router.routerState.snapshot.url);
      }, expires);

      const user = this.lektoAuthService.getUserInfoData();

      if (user) {
        Sentry.setUser({
          email: user.email,
          id: `${user.id_user} | ${user.sub}`,
          ip_address: "{{auto}}",
          username: user.name,
        });
      }
    } else {
      Sentry.setUser(null);
    }

    this.resetIdleState();
  }

  ngAfterViewInit(): void {
    this.watchModalScrolls();
  }

  ngOnDestroy(): void {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }

    this.mutationObserver?.disconnect();
  }

  watchModalScrolls() {
    const antModalClass = "ant-modal-wrap";

    const scrollListener = (_event: Event) => {
      document.querySelectorAll(".ant-select-dropdown").forEach((item) => {
        item.remove();
        document.body.click();
      });
    };

    this.ngZone.runOutsideAngular(() => {
      new ResizeObserver(() => {
        this.mutationObserver?.disconnect();
        this.mutationObserver = new MutationObserver((mutations) => {
          for (const mutation of mutations) {
            if (mutation.addedNodes.length > 0) {
              const addedNodes = Array.from(
                mutation.addedNodes
              ) as HTMLElement[];

              const modalNode = addedNodes.find(
                (p) => p.classList?.contains(antModalClass) === true
              );

              if (modalNode) {
                modalNode.addEventListener("scroll", scrollListener);
                break;
              }
            } else if (mutation.removedNodes.length > 0) {
              const removedNodes = Array.from(
                mutation.removedNodes
              ) as HTMLElement[];

              const modalNode = removedNodes.find(
                (p) => p.classList?.contains(antModalClass) === true
              );

              if (modalNode) {
                modalNode.removeEventListener("scroll", scrollListener);
                break;
              }
            }
          }
        });
        this.mutationObserver.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }).observe(document.body);
    });
  }

  startIdleWatcher() {
    // how long can they be inactive before considered idle, in seconds
    this.idle.setIdle(60 * 60);
    // how long can they be idle before considered timed out, in seconds
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    // will ping at this interval while not idle, in seconds
    this.keepalive.interval(15);

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.countdown = null;
    });

    this.idle.onTimeoutWarning.subscribe((seconds) => {
      this.idleState = "You will time out in " + seconds + " seconds!";
      this.countdown = seconds;
    });

    this.idle.onTimeout.subscribe(() => {
      if (this.timedOut) {
        return;
      }

      if (this.lektoAuthService.isLoggedIn()) {
        this.idleState = "Timed out!";
        this.timedOut = true;
        this.lektoAuthService.logout(this.router.routerState.snapshot.url);
      } else {
        this.resetIdleState();
      }
    });

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  resetIdleState() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
    this.lastPing = null;
    this.countdown = null;
  }

  buildSegments(route: ActivatedRouteSnapshot): string[] {
    let crumbs$: string[];
    const data = route.routeConfig && route.routeConfig.data;

    if (data) {
      crumbs$ = this.resolveSegments(route);
    } else {
      crumbs$ = [];
    }

    if (route.firstChild) {
      crumbs$ = crumbs$.concat(this.buildSegments(route.firstChild));
    }

    return crumbs$;
  }

  resolveSegments(route: ActivatedRouteSnapshot): string[] {
    const path = this.getFullPath(route);
    return [path];
  }

  getFullPath(route: ActivatedRouteSnapshot): string {
    const relativePath = (segments: UrlSegment[]) =>
      segments.reduce((a, v) => a + "/" + v.path, "");
    const fullPath = (routes: ActivatedRouteSnapshot[]) =>
      routes.reduce((a, v) => a + relativePath(v.url), "");
    return fullPath(route.pathFromRoot);
  }
}
