import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { LektoAuthService } from "../services/essential/lekto-auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: LektoAuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.isAccessAllowed(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.isAccessAllowed(childRoute, state);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authService.isLoggedIn()) {
      this.authService.login(state.url);
      return false;
    }

    // Get the roles required from the route.
    const requiredRoles = route.data["profiles"];

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    const hasAccess = this.authService.hasProfile(requiredRoles);

    if (!hasAccess) {
      this.router.navigate(["/403"]);
    }

    return hasAccess;
  }
}
