import onCacheWrite from './onCacheWrite';
export default (function (_a) {
  var log = _a.log,
    cache = _a.cache;
  return function (persist) {
    log.warn('Trigger option `background` not available on web; using `write` trigger');
    return onCacheWrite({
      cache: cache
    })(persist);
  };
});
