/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { Constants } from "../../constants";
import { CustomAPIErrorResponse } from "../../models/essential/custom-api-error-response";

@Injectable({
  providedIn: "root",
})
export class customHttpErrorModal {
  constructor(private modal: NzModalService) {}

  public showModal = (err: any): void => {
    if (Constants.HTTP_API_CUSTOM_ERRORS.some((e) => e == err.error?.type)) {
      const errorObj = <CustomAPIErrorResponse>err.error;
      let descriptionData = "";

      if (errorObj.data.length > 0) {
        descriptionData = "<ul>";
        errorObj.data.forEach((item) => {
          descriptionData += `<li><strong>${item}</strong></li>`;
        });
        descriptionData += "</ul>";
      }

      this.modal.warning({
        nzTitle: errorObj.title,
        nzContent: `
                    ${errorObj.description}
                    <hr class="mb-3 mt-3">
                    ${descriptionData}
                `,
      });
    } else if (err instanceof HttpErrorResponse) {
      if (err.status === 422) {
        const msg = err.error?.message || Constants.DEFAULT_ERROR_MESSAGE;
        this.modal.warning({
          nzTitle: "Atenção",
          nzContent: msg,
        });
      } else if (err.status == 400 && err.error.errors) {
        const messages: string[] = [];

        const keys = Object.keys(err.error.errors);

        keys.forEach((errorKey) => {
          const obj = err.error.errors[errorKey];
          messages.push(`[${errorKey}]<br>${obj[0]}`);
        });

        this.modal.error({
          nzTitle: "Erros encontrados",
          nzContent: messages.join("<br><br>"),
        });
      } else {
        this.showDefaultError(err);
      }
    } else {
      this.showDefaultError(err);
    }
  };

  showDefaultError(err: any) {
    this.modal.error({
      nzTitle: "Erro ao processar requisição",
      nzContent: `
            Tente novamente, caso o erro persista entre em contato como administrador do sistema!
            <hr class="mb-3 mt-3">
            <strong>Error</strong>: ${err.error?.title || err.message}
            <br>
            <br>
            <strong>Status</strong>: ${err.statusText}
            `,
    });
  }
}
