<div nz-row nzAlign="middle" class="mt-4">
  <div nz-col [nzSpan]="24" style="text-align: center">
    <br />
    <img
      src="../../../assets/images/marca.png"
      alt="Lekto"
      style="margin: 0px auto" />
    <br /><br /><br />
    <nz-result
      nzStatus="403"
      nzTitle="403"
      nzSubTitle="Desculpe, você não tem permissão para acessar a página requisitada.">
      <div nz-result-extra>
        <a role="button" nz-button nzType="primary" [routerLink]="['/']"
          >Página Inicial</a
        >

        <button nz-button (click)="logout()">Sair</button>
      </div>
    </nz-result>
  </div>
</div>
