import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isMenuSelectedPipe",
  standalone: true,
})
export class IsMenuSelectedPipePipe implements PipeTransform {
  transform(value: string[]): boolean {
    if (!value || value.length <= 0) {
      return false;
    }

    return window.location.pathname === value.join("/");
  }
}
