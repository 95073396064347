import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, RouterLink, RouterOutlet } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { Subscription } from "rxjs";
import { Constants } from "src/app/shared/constants";
import { LektoAuthService } from "src/app/shared/services/essential/lekto-auth.service";
import { RouteParamsService } from "src/app/shared/services/essential/route-params.service";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { SchoolHeaderComponent } from "./components/school-header/school-header.component";
import { HeaderComponent } from "./components/header/header.component";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
  styleUrls: ["./full-layout.component.scss"],
  standalone: true,
  imports: [
    HeaderComponent,
    SchoolHeaderComponent,
    NzPageHeaderModule,
    NzBreadCrumbModule,
    RouterLink,
    NzLayoutModule,
    RouterOutlet,
    EssentialsModule,
  ],
})
export class FullLayoutComponent implements OnInit, OnDestroy {
  readonly Constants = Constants;
  isAdmin = false;
  isSchoolManager = false;
  isSchoolNetworkAdmin = false;
  isRegionalAdmin = false;
  routerSegments$?: Subscription;
  routerSegments?: string[];
  showUpdateAvailable = false;
  notify: string = "";
  notifyView: boolean = false;

  constructor(
    private router: Router,
    private paramsService: RouteParamsService,
    public authService: LektoAuthService,
    private swUpdate: SwUpdate
  ) {
    this.watchUpdates();
  }

  ngOnInit(): void {
    this.isAdmin = this.authService.hasProfile([Constants.PROFILES.LektoAdmin]);
    this.isSchoolManager = this.authService.hasProfile([
      Constants.PROFILES.SchoolManager,
    ]);
    this.isSchoolNetworkAdmin = this.authService.hasProfile([
      Constants.PROFILES.SchoolNetworkAdmin,
    ]);
    this.isRegionalAdmin = this.authService.hasProfile([
      Constants.PROFILES.RegionalAdmin,
    ]);

    localStorage.setItem(
      "profiles",
      JSON.stringify({
        isAdmin: this.isAdmin,
        isSchoolAdmin: this.isSchoolManager,
        isSchoolManager: this.isSchoolManager,
        isSchoolNetworkAdmin: this.isSchoolNetworkAdmin,
        isRegionalAdmin: this.isRegionalAdmin,
      })
    );

    this.routerSegments$ = this.paramsService.routerSegments$.subscribe(
      (segments) => {
        this.routerSegments = segments?.filter((item) => item);
      }
    );

    if (
      this.isSchoolManager ||
      this.isSchoolNetworkAdmin ||
      this.isRegionalAdmin
    ) {
      const allowedRoutes = ["/user-management", "/reports"];

      if (
        this.isSchoolManager &&
        !this.isRegionalAdmin &&
        !this.isSchoolNetworkAdmin
      ) {
        this.router.navigate(["/school"]);
      } else {
        if (!allowedRoutes.some((p) => this.router.url.startsWith(p))) {
          this.router.navigate(["/school"]);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.routerSegments$?.unsubscribe();
  }

  get isSchoolHeaderVisible(): boolean {
    return (
      this.router.url.toLowerCase().startsWith("/school/") &&
      (this.isAdmin ||
        this.isSchoolManager ||
        this.isSchoolManager ||
        this.isSchoolNetworkAdmin ||
        this.isRegionalAdmin)
    );
  }

  onBack() {
    if (!this.routerSegments) {
      return;
    }

    this.routerSegments.pop();

    if (this.routerSegments.length > 0) {
      const url = this.routerSegments?.pop()?.split("/");
      this.router.navigate(url ?? []);
    }
  }

  watchUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case "VERSION_READY":
            this.showUpdateAvailable = true;
            break;
        }
      });
    }
  }

  reload() {
    this.swUpdate.activateUpdate().then((result) => {
      if (result == true) {
        window.location.reload();
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setBreadCrumb(label: any): string {
    if (typeof label === "string") {
      return label;
    } else {
      return label();
    }
  }
}
