<ul nz-menu nzMode="horizontal">
  @if (showManageMenu) {
    <li nz-submenu [nzTitle]="registrationTpl">
      <ul>
        @for (item of manageMenuItems; track item) {
          <li nz-menu-item nzMatchRouter class="registration-menu-item">
            <a style="color: black" [routerLink]="item.route">{{
              item.title
            }}</a>
          </li>
        }
      </ul>
    </li>
  }

  <!-- Integração -->
  @if (showManageMenu) {
    <li nz-menu-item nzMatchRouter>
      <div class="menu-container">
        <app-unicon iconName="plug" size="20px" />
        <a [routerLink]="['integration', 'viewer']">Integração</a>
      </div>
    </li>
  }

  <li nz-menu-item nzMatchRouter>
    <div class="menu-container">
      <app-unicon iconName="university" size="20px" />
      <a [routerLink]="['school']">Escolas</a>
    </div>
  </li>

  @if (canShowUserManagement()) {
    <li nz-menu-item nzMatchRouter>
      <div class="menu-container">
        <app-unicon iconName="user-square" size="20px" />
        <a [routerLink]="['/', 'user-management']">Gestão de Usuários</a>
      </div>
    </li>
  }

  <li nz-menu-item nzMatchRouter>
    <div class="menu-container">
      <app-unicon iconName="graph-bar" size="20px" />
      <a [routerLink]="['/', 'dashboard']">Painel</a>
    </div>
  </li>

  @if (canShowReportsMenu()) {
    <li nz-submenu [nzTitle]="reportsTpl">
      <ul>
        <li nz-menu-item nzMatchRouter>
          <a style="color: black" [routerLink]="['/', 'reports', 'licenses']">
            Licenças de Alunos
          </a>
        </li>
      </ul>
    </li>
  }

  <li nz-menu-item nzMatchRouter>
    <div class="menu-container">
      <app-unicon iconName="file-bookmark-alt" size="20px" />
      <a [routerLink]="['/', 'track']">Trilhas Lekto</a>
    </div>
  </li>
</ul>

<ng-template #registrationTpl>
  <div class="menu-container">
    <app-unicon iconName="edit-alt" size="20px" />
    <span>Cadastros</span>
  </div>
</ng-template>

<ng-template #reportsTpl>
  <div class="menu-container">
    <app-unicon iconName="file-graph" size="20px" />
    <span>Relatórios</span>
  </div>
</ng-template>
