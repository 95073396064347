import { Component, ChangeDetectionStrategy } from "@angular/core";
import { UserInfo } from "src/app/shared/models/user/user-info.model";
import { LektoAuthService } from "src/app/shared/services/essential/lekto-auth.service";
import { Md5 } from "ts-md5";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";

@Component({
  selector: "[userMenu]",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NzDropDownModule,
    NzAvatarModule,
    NzIconModule,
    NzMenuModule,
    EssentialsModule,
  ],
})
export class UserMenuComponent {
  userInfo: UserInfo;

  constructor(private auth: LektoAuthService) {
    this.userInfo = this.auth.getUserInfoData()!;
  }

  logout() {
    this.auth.logout();
  }

  get simplifiedName(): string {
    if (!this.userInfo) return "";

    const split = this.userInfo.name.split(" ");

    if (split.length > 1) {
      return `${split[0]}`;
    } else {
      return this.userInfo.name;
    }
  }

  public getAvatar() {
    const defaultUrl = `${window.location.origin}/assets/images/profile.png`;

    if (!this.userInfo) return defaultUrl;

    const picture = this.userInfo?.picture;

    if (!picture) {
      const mailHash = Md5.hashStr(this.userInfo.email);
      const userGravatarImg = `https://www.gravatar.com/avatar/${mailHash}?s=40&r=g&d=${defaultUrl}`;
      return userGravatarImg;
    } else {
      return picture;
    }
  }
}
