import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { School } from "src/app/shared/models/school/school";
import { RouteParamsService } from "src/app/shared/services/essential/route-params.service";
import { SchoolMenuComponent } from "../school-menu/school-menu.component";

import { NzGridModule } from "ng-zorro-antd/grid";

@Component({
  selector: "app-school-header",
  templateUrl: "./school-header.component.html",
  styleUrls: ["./school-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzGridModule, SchoolMenuComponent],
})
export class SchoolHeaderComponent implements OnInit, OnDestroy {
  schoolSub$?: Subscription;
  schoolData?: School;

  routerSegments$?: Subscription;
  routerSegments?: string[];

  constructor(
    private paramsService: RouteParamsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.schoolSub$ = this.paramsService.schoolData$.subscribe((data) => {
      this.schoolData = data;
      localStorage.setItem("schoolData", JSON.stringify(data));
    });

    this.routerSegments$ = this.paramsService.routerSegments$.subscribe(
      (segments) => {
        this.routerSegments = segments?.filter((item) => item);
      }
    );
  }

  onBack() {
    if (!this.routerSegments) {
      return;
    }

    this.routerSegments.pop();

    if (this.routerSegments.length > 0) {
      const url = this.routerSegments?.pop()?.split("/");
      this.router.navigate(url ?? []);
    }
  }

  ngOnDestroy(): void {
    this.schoolSub$?.unsubscribe();

    this.routerSegments$?.unsubscribe();
  }
}
