import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NzResultModule } from "ng-zorro-antd/result";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";
import { LektoAuthService } from "src/app/shared/services/essential/lekto-auth.service";

@Component({
  selector: "app-forbidden",
  templateUrl: "./forbidden.component.html",
  styleUrls: ["./forbidden.component.scss"],
  standalone: true,
  imports: [NzResultModule, RouterLink, EssentialsModule],
})
export class ForbiddenComponent {
  constructor(private authService: LektoAuthService) {}

  logout() {
    this.authService.logout();
  }
}
