import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Constants } from "../constants";
import { LektoAuthService } from "../services/essential/lekto-auth.service";
import { Router } from "@angular/router";
import { customHttpErrorModal } from "../services/essential/custom-modal-http.service";

interface IHeaders {
  Authorization: string;
  "x-user-type": string;
  "x-district": string;
  "x-school": string;
  [key: string]: string | string[];
}

export const customHttpInterceptor: HttpInterceptorFn = (req, next) => {
  const allowedUrls = [environment.apiUrl, environment.serviceUrl, "/"];

  const authService = inject(LektoAuthService);
  const router = inject(Router);
  const customModalService = inject(customHttpErrorModal);

  if (
    authService.isLoggedIn() &&
    allowedUrls.some((p) => req.url.toLocaleLowerCase().startsWith(p))
  ) {
    if (authService.isLoggedIn()) {
      const tokenData = authService.getTokenData();
      const headers = {} as IHeaders;
      headers.Authorization = `Bearer ${tokenData?.access_token}`;

      if (tokenData?.coProfile) {
        headers["x-user-type"] = tokenData?.coProfile;
      }

      if (tokenData?.coDistrict) {
        headers["x-district"] = tokenData?.coDistrict;
      }

      if (tokenData?.idSchool) {
        headers["x-school"] = tokenData?.idSchool.toString();
      }

      req = req.clone({
        setHeaders: headers,
      });
    }
  }

  return next(req).pipe(
    catchError((err) => {
      if (req.headers.has(Constants.HEADER_KEY_IGNORE_ERROR)) {
        return of();
      } else if (err instanceof HttpErrorResponse) {
        if (err.status === 403) {
          router.navigate(["/403"]);
        } else if (err.status === 401) {
          authService.logout(router.routerState.snapshot.url);
        }
      } else {
        // Chamar serviço para exibir uma modal customizada dependendo do tipo de erro
        customModalService.showModal(err);
      }

      throw err;
    })
  );
};
