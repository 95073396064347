import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { Constants } from "src/app/shared/constants";
import { LektoAuthService } from "src/app/shared/services/essential/lekto-auth.service";
import { RouterLink } from "@angular/router";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";

export interface MenuItem {
  title: string;
  route: string[];
  icon?: string;
  svgIcon?: string;
  id?: number;
}

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzMenuModule, RouterLink, EssentialsModule],
})
export class MenuComponent implements OnInit {
  showManageMenu = false;
  showSchoolMenu = false;
  showPerformanceMenu = false;

  isAdmin = this.authService.hasProfile([Constants.PROFILES.LektoAdmin]);
  isRegionalAdmin = this.authService.hasProfile([
    Constants.PROFILES.RegionalAdmin,
  ]);
  isNetworkAdmin = this.authService.hasProfile([
    Constants.PROFILES.SchoolNetworkAdmin,
  ]);
  isSchoolManager = this.authService.hasProfile([
    Constants.PROFILES.SchoolManager,
  ]);

  manageMenuItems: MenuItem[] = [];

  constructor(
    private authService: LektoAuthService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.isAdmin) {
      this.manageMenuItems = [];

      this.manageMenuItems.push({
        title: "Aulas",
        route: ["/manage", "lesson"],
      });
      this.manageMenuItems.push({
        title: "Projetos",
        route: ["/manage", "projects"],
      });
      this.manageMenuItems.push({
        title: "Trilhas de Aulas",
        route: ["/manage", "lesson-track"],
      });
      this.manageMenuItems.push({
        title: "Trilhas de Projetos",
        route: ["/manage", "project-track"],
      });
      this.manageMenuItems.push({
        title: "Categorias",
        route: ["/manage", "category"],
      });
      this.manageMenuItems.push({
        title: "Componentes",
        route: ["/manage", "component"],
      });
      this.manageMenuItems.push({
        title: "Competências e Habilidades",
        route: ["/manage", "competence"],
      });
    }

    this.showManageMenu = this.isAdmin;
    // this.showPerformanceMenu = this.isNetworkAdmin || this.isRegionalAdmin;
    this.cdr.detectChanges();
  }

  canShowUserManagement(): boolean {
    return this.isAdmin || this.isNetworkAdmin || this.isRegionalAdmin;
  }

  canShowReportsMenu(): boolean {
    return this.isAdmin || this.isNetworkAdmin || this.isRegionalAdmin;
  }
}
