import { registerLocaleData } from "@angular/common";
import {
  HttpErrorResponse,
  provideHttpClient,
  withInterceptors,
} from "@angular/common/http";
import pt from "@angular/common/locales/pt";
import {
  ApplicationConfig,
  importProvidersFrom,
  LOCALE_ID,
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
} from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  InMemoryScrollingOptions,
  Router,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
} from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { NZ_I18N, pt_BR, NZ_DATE_LOCALE } from "ng-zorro-antd/i18n";
import { environment } from "src/environments/environment";
import { GraphQLModule } from "./shared/modules/graphql.module";
import { SharedModule } from "./shared/modules/shared.module";
import { ptBR } from "date-fns/locale";

import "../app/shared/extensions/form-group.extensions";
import "../app/shared/extensions/string.extensions";
import "../app/shared/extensions/array.extensions";
import "../app/shared/extensions/object.extensions";

import packageInfo from "../../package.json";

import * as Sentry from "@sentry/angular-ivy";
import { routes } from "./app-routing.module";
import { customHttpInterceptor } from "./shared/interceptors/custom-http.interceptor";
import setDateFnsDefault from "date-fns/setDefaultOptions";

Sentry.init({
  release: `${packageInfo.name}@${packageInfo.version}`,
  dsn: "https://435230fdceed4cce99b9dda950cb3041@sentry.lekto.com.br/4",
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
  ],
  tracePropagationTargets: [
    "localhost",
    environment.apiUrl,
    environment.serviceUrl,
  ],
  beforeSend: (event, hint) => {
    if (!environment.production) return null;

    if (hint.originalException instanceof HttpErrorResponse) {
      const err = hint.originalException as HttpErrorResponse;

      if (err?.status == 404) {
        return null;
      }
    } else if (typeof hint.originalException == "string") {
      if (hint.originalException.includes(" 404 ")) {
        return null;
      }
    }

    return event;
  },
  debug: false,
  environment: environment.environmentName,
  attachStacktrace: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

setDateFnsDefault({
  locale: ptBR,
});
registerLocaleData(pt);

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: "enabled",
};

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      LoadingBarHttpClientModule,
      NgIdleKeepaliveModule.forRoot(),
      GraphQLModule,
      SharedModule
    ),
    { provide: NZ_I18N, useValue: pt_BR },
    { provide: NZ_DATE_LOCALE, useValue: ptBR },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling(scrollConfig)
    ),
    provideServiceWorker("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    provideAnimations(),
    provideHttpClient(withInterceptors([customHttpInterceptor])),
  ],
};
