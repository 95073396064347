import { ElementRef } from "@angular/core";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";

const runMarkAllAsDirty = (form: FormGroup) => {
  for (const i in form.controls) {
    form.controls[i].markAsDirty();
    form.controls[i].markAllAsTouched();
    form.controls[i].updateValueAndValidity();
  }
};

declare module "@angular/forms" {
  interface FormGroup {
    markAllAsDirty(this: FormGroup): void;
  }
}

UntypedFormGroup.prototype.markAllAsDirty = function (
  this: UntypedFormGroup
): void {
  runMarkAllAsDirty(this);
};

FormGroup.prototype.markAllAsDirty = function (this: FormGroup): void {
  runMarkAllAsDirty(this);
};

UntypedFormGroup.prototype.scrollToFirstInvalidControl = function (
  this: UntypedFormGroup,
  el: ElementRef
): void {
  setTimeout(() => {
    if (this.invalid) {
      const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
        ".ant-form-item-has-error"
      );

      if (!firstInvalidControl) {
        return;
      }

      firstInvalidControl.scrollIntoView({ behavior: "smooth" });
      fromEvent(window, "scroll")
        .pipe(debounceTime(100), take(1))
        .subscribe(() => firstInvalidControl.focus());
    }
  }, 50);
};

export {};
