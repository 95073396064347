import { ChangeDetectionStrategy, Component } from "@angular/core";
import packageInfo from "../../../../../../package.json";
import { environment } from "src/environments/environment";
import { Constants } from "src/app/shared/constants";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";
import { SharedModule } from "../../../../shared/modules/shared.module";
import { UiService } from "../../../../shared/services/essential/ui.service";
import { LektoAuthService } from "../../../../shared/services/essential/lekto-auth.service";
import { UserAuthService } from "../../../../shared/services/user/user-auth.service";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzDropDownModule, NzMenuModule, EssentialsModule, SharedModule],
})
export class FaqComponent {
  version = packageInfo.version;
  buildVersion = packageInfo.buildVersion;
  featureFlag: boolean = environment.environmentName !== Constants.ENVIRONMENT_NAMES.PROD;

  constructor(
    private authService: UserAuthService) {
  }

  goToZendesk(url: string) {
    this.authService.redirectToZendesk(url);
  }
}
