/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of, tap } from "rxjs";
import { SchoolService } from "../services/school/school.service";
import { School } from "../models/school/school";
import { RouteParamsService } from "../services/essential/route-params.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationInfoResolver {
  constructor(
    private schoolService: SchoolService,
    private paramsService: RouteParamsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<School | null | undefined> {
    const idSchool = +route.params["idSchool"];

    if (idSchool && idSchool > 0) {
      return this.schoolService
        .getIntegrationInfo(idSchool)
        .pipe(
          tap((obj: any) => this.paramsService.integrationInfoData$.next(obj))
        );
    } else {
      return of(null);
    }
  }
}
