import { NgModule } from "@angular/core";
import { EssentialsModule } from "./essentials.module";
import { QuillModule } from "ngx-quill";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { NgEventBus } from "ng-event-bus";
import { CookieService } from "ngx-cookie-service";
import { UiService } from "../services/essential/ui.service";
import { Delta } from "quill/core";

/**
 * Módulo com as configurações globais e dependências globais do sistema.
 *
 * Não importar nos componentes, utilizar o EssessentialsModule.
 */
@NgModule({
  declarations: [],
  imports: [
    QuillModule.forRoot({
      placeholder: "Insira o texto aqui...",
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }], // List options
        ],
        clipboard: {
          matchVisual: false,
          matchers: [
            [
              Node.TEXT_NODE,
              (_node: string, delta: Delta) => {
                if (delta.ops) {
                  delta.ops.forEach((op) => {
                    if (op.attributes) {
                      delete op.attributes.color;
                    }
                    op.attributes = {
                      ...op.attributes,
                      color: "#000000",
                      background: "none",
                    };
                  });
                }
                return delta;
              },
            ],
          ],
        },
      },
    }),
  ],
  exports: [EssentialsModule],
  providers: [
    provideEnvironmentNgxMask(),
    CookieService,
    NgEventBus,
    UiService,
  ],
})
export class SharedModule {}
