import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UserMenuComponent } from "../user-menu/user-menu.component";
import { FaqComponent } from "../faq/faq.component";
import { MenuComponent } from "../menu/menu.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MenuComponent, FaqComponent, UserMenuComponent],
})
export class HeaderComponent {
  @Input()
  public isAdmin = false;
}
