@if (idSchool) {
  <ul nz-menu nzMode="horizontal">
    @for (item of menuItemList; track trackByFn($index, item)) {
      <li nz-menu-item [nzSelected]="item.route | isMenuSelectedPipe">
        <div
          style="
            display: flex;
            gap: 8px;
            align-items: center;
            font-weight: 500px;
          ">
          <app-unicon [iconName]="item.icon" size="20px" />
          <a style="font-size: 14px" [routerLink]="item.route">{{
            item.title
          }}</a>
        </div>
      </li>
    }
  </ul>
}
