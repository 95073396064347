/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { tap } from "rxjs/operators";
import { RouteParamsService } from "../services/essential/route-params.service";
import { StudentService } from "../services/student/student.service";

@Injectable({
  providedIn: "root",
})
export class StudentLicenseReportResolver {
  constructor(
    private paramsService: RouteParamsService,
    private studentService: StudentService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): Observable<StudentLicenseReportResolver> {
    const id = route.params["id"];
    return this.studentService.getLicenseSummary(id).pipe(
      tap((obj: any) => {
        localStorage.setItem(
          "selectedLicenseReport",
          JSON.stringify({
            networkName: obj.networkName,
            competenceDescription: obj.competenceDescription,
          })
        );
        this.paramsService.schoolData$.next(obj);
      })
    );
  }
}
