var MMKVWrapper = function () {
  function MMKVWrapper(storage) {
    this.storage = storage;
  }
  MMKVWrapper.prototype.getItem = function (key) {
    return this.storage.getString(key) || null;
  };
  MMKVWrapper.prototype.removeItem = function (key) {
    return this.storage.delete(key);
  };
  MMKVWrapper.prototype.setItem = function (key, value) {
    if (value !== null) {
      return this.storage.set(key, value);
    }
    return this.removeItem(key);
  };
  return MMKVWrapper;
}();
export { MMKVWrapper };
