export {};

declare global {
  interface ObjectConstructor {
    toMutable<T>(obj: T): T;
  }
}

Object.toMutable = function <T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
};
