<header class="school-header">
  <nz-row nzAlign="middle">
    <nz-col nzFlex="auto">
      <div style="display: flex; align-items: center; gap: 16px">
        <h1 class="school-name">
          {{ schoolData?.txName }}
        </h1>

        @if (schoolData?.network) {
          <span class="school-network">{{ schoolData?.network?.txName }}</span>
        }
      </div>
    </nz-col>

    <app-school-menu />
  </nz-row>
</header>
