import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NzResultModule } from "ng-zorro-antd/result";
import { EssentialsModule } from "src/app/shared/modules/essentials.module";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
  standalone: true,
  imports: [NzResultModule, RouterLink, EssentialsModule],
})
export class NotFoundComponent {}
