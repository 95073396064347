String.prototype.digits = function (this: string): string {
  return this.replace(/[^0-9]*/g, "");
};

String.prototype.digitsAndLetters = function (this: string): string {
  return this.replace(/[^a-z0-9]/gi, "");
};

String.prototype.pick = function (min, max) {
  let n,
    chars = "";

  if (typeof max === "undefined") {
    n = min;
  } else {
    n = min + Math.floor(Math.random() * (max - min + 1));
  }

  for (let i = 0; i < n; i++) {
    chars += this.charAt(Math.floor(Math.random() * this.length));
  }

  return chars;
};

String.prototype.shuffle = function () {
  const array = this.split("");
  let tmp,
    current,
    top = array.length;

  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }

  return array.join("");
};

export {};
