var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
var Log = function () {
  function Log(options) {
    var _a = options.debug,
      debug = _a === void 0 ? false : _a;
    this.debug = debug;
    this.lines = [];
  }
  Log.prototype.emit = function (level, message) {
    if (level in console) {
      var prefix = Log.prefix;
      console[level].apply(console, __spreadArray([prefix], message, false));
    }
  };
  Log.prototype.tailLogs = function () {
    var _this = this;
    this.lines.forEach(function (_a) {
      var level = _a[0],
        message = _a[1];
      return _this.emit(level, message);
    });
  };
  Log.prototype.getLogs = function () {
    return this.lines;
  };
  Log.prototype.write = function (level, message) {
    var buffer = Log.buffer;
    this.lines = __spreadArray(__spreadArray([], this.lines.slice(1 - buffer), true), [[level, message]], false);
    if (this.debug || level !== 'log') {
      this.emit(level, message);
    }
  };
  Log.prototype.info = function () {
    var message = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      message[_i] = arguments[_i];
    }
    this.write('log', message);
  };
  Log.prototype.warn = function () {
    var message = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      message[_i] = arguments[_i];
    }
    this.write('warn', message);
  };
  Log.prototype.error = function () {
    var message = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      message[_i] = arguments[_i];
    }
    this.write('error', message);
  };
  Log.buffer = 30;
  Log.prefix = '[apollo-cache-persist]';
  return Log;
}();
export default Log;
