@if (showUpdateAvailable) {
  <div nz-row>
    <div nz-col nzSpan="24" class="update-available">
      <app-unicon iconName="info-circle" />

      <div>
        Uma nova versão do sistema está disponível,
        <a (click)="reload()">clique aqui para atualizar.</a>
      </div>
    </div>
  </div>
}

<app-header [isAdmin]="isAdmin" />
@if (isSchoolHeaderVisible) {
  <app-school-header />
}

@if (routerSegments && routerSegments.length > 0) {
  <div class="breadcrumb-container">
    <nz-page-header
      [nzTitle]="breadcrumb"
      (nzBack)="onBack()"
      style="padding: 0px" />

    <ng-template #breadcrumb>
      <nz-breadcrumb
        [nzRouteLabelFn]="setBreadCrumb"
        [nzAutoGenerate]="true"
        style="font-weight: 500; margin-left: -5px">
        <nz-breadcrumb-item>
          <a [routerLink]="['/']"><span nz-icon nzType="home"></span></a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </ng-template>
  </div>
}

<nz-content>
  <div class="content">
    <router-outlet />
  </div>
</nz-content>
