<header class="lekto-header">
  <div class="flex" style="gap: 56px">
    @if (isAdmin) {
      <a href="{{ isAdmin ? '' : '/school' }}" title="Lekto">
        <img src="../../../assets/images/marca.png" alt="Lekto" />
      </a>
    }

    <app-menu />
  </div>

  <div class="flex" style="gap: 12px">
    <app-faq />

    <div userMenu></div>
  </div>
</header>
