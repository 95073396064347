import { Routes } from "@angular/router";
import { FullLayoutComponent } from "./layout/full-layout/full-layout.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { Constants } from "./shared/constants";
import { SchoolEssentialsResolver } from "./shared/resolvers/school-essentials.resolver";
import { ForbiddenComponent } from "./pages/forbidden/forbidden.component";
import { HangfireComponent } from "./pages/hangfire/hangfire.component";
import { AuthGuard } from "./shared/guard/auth.guard";
import { IntegrationInfoResolver } from "./shared/resolvers/integration-info.resolver";
import { StudentLicenseReportResolver } from "./shared/resolvers/student-license-report.resolver";

export const routes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      profiles: [
        Constants.PROFILES.LektoAdmin,
        Constants.PROFILES.SchoolNetworkAdmin,
        Constants.PROFILES.RegionalAdmin,
        Constants.PROFILES.SchoolManager,
      ],
      breadcrumb: "/",
    },
    component: FullLayoutComponent,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/home-page/home-page.component").then(
            (m) => m.HomePageComponent
          ),
        data: { breadcrumb: "/" },
      },
      {
        path: "manage",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          profiles: [
            Constants.PROFILES.LektoAdmin,
            Constants.PROFILES.SchoolNetworkAdmin,
            Constants.PROFILES.SchoolManager,
            Constants.PROFILES.RegionalAdmin,
          ],
          breadcrumb: "Cadastros",
        },
        children: [
          { path: "", redirectTo: "/", pathMatch: "full" },
          {
            path: "component",
            loadComponent: () =>
              import("./pages/component/component-page.component").then(
                (m) => m.ComponentPageComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Componentes",
            },
          },

          {
            path: "lesson",
            loadComponent: () =>
              import("./pages/lesson/lesson-page.component").then(
                (m) => m.LessonPageComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Aulas",
            },
          },
          {
            path: "lesson-track",
            loadComponent: () =>
              import("./pages/lesson-track/lesson-track.component").then(
                (m) => m.LessonTrackComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Trilhas de Aulas",
            },
          },
          {
            path: "project-track",
            loadComponent: () =>
              import("./pages/project-track/project-track.component").then(
                (m) => m.ProjectTrackComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Trilhas de Projetos",
            },
          },
          {
            path: "projects",
            loadComponent: () =>
              import("./pages/project/project-page.component").then(
                (m) => m.ProjectRegistrationPageComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Projetos",
            },
          },
          {
            path: "category",
            loadComponent: () =>
              import("./pages/category/category.component").then(
                (m) => m.CategoryComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Categorias",
            },
          },
          {
            path: "competence",
            loadComponent: () =>
              import("./pages/competence/competence.component").then(
                (m) => m.CompetenceComponent
              ),
            data: {
              profiles: [Constants.PROFILES.LektoAdmin],
              breadcrumb: "Competências e Habilidades",
            },
          },
        ],
      },
      {
        path: "integration",
        data: {
          breadcrumb: "Integração",
          profiles: [Constants.PROFILES.LektoAdmin],
        },
        canActivateChild: [AuthGuard],
        children: [
          {
            path: "viewer",
            loadComponent: () =>
              import(
                "./pages/integration-viewer/integration-viewer.component"
              ).then((m) => m.IntegrationViewerComponent),
            data: { breadcrumb: "Registros" },
          },
        ],
      },
      {
        path: "user-management",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          profiles: [
            Constants.PROFILES.LektoAdmin,
            Constants.PROFILES.SchoolNetworkAdmin,
            Constants.PROFILES.RegionalAdmin,
          ],
          breadcrumb: "Gestão de usuários",
        },
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/user-management/user-management.component").then(
                (m) => m.UserManagementComponent
              ),
          },
        ],
      },

      {
        path: "dashboard",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          profiles: [
            Constants.PROFILES.LektoAdmin,
            Constants.PROFILES.SchoolNetworkAdmin,
            Constants.PROFILES.RegionalAdmin,
            Constants.PROFILES.SchoolManager,
          ],
          breadcrumb: "Painel",
        },
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/dashboard/dashboard.component").then(
                (m) => m.DashboardComponent
              ),
          },
          {
            path: "student/:idUser",
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            data: {
              profiles: [
                Constants.PROFILES.LektoAdmin,
                Constants.PROFILES.SchoolNetworkAdmin,
                Constants.PROFILES.RegionalAdmin,
                Constants.PROFILES.SchoolManager,
              ],
              breadcrumb: "Aluno",
            },
            children: [
              {
                path: "",
                loadComponent: () =>
                  import(
                    "./pages/student-history/student-history.component"
                  ).then((m) => m.StudentHistoryComponent),
              },
            ],
          },
        ],
      },
      {
        path: "track",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {
          profiles: [
            Constants.PROFILES.SuperAdmin,
            Constants.PROFILES.LektoAdmin,
            Constants.PROFILES.SchoolNetworkAdmin,
            Constants.PROFILES.RegionalAdmin,
            Constants.PROFILES.SchoolManager,
          ],
          breadcrumb: "Trilhas Lekto",
        },
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/track/track.component").then(
                (m) => m.TrackComponent
              ),
          },
        ],
      },

      {
        path: "school",
        data: {
          breadcrumb: "Escolas",
        },
        canActivateChild: [AuthGuard],
        children: [
          {
            path: "",
            loadComponent: () =>
              import("./pages/school-list/school-list-page.component").then(
                (m) => m.SchoolListPageComponent
              ),
            data: {
              profiles: [
                Constants.PROFILES.LektoAdmin,
                Constants.PROFILES.SchoolNetworkAdmin,
                Constants.PROFILES.RegionalAdmin,
                Constants.PROFILES.SchoolManager,
              ],
            },
          },
          {
            path: ":idSchool",
            canActivate: [AuthGuard],
            canActivateChild: [AuthGuard],
            resolve: {
              data: SchoolEssentialsResolver,
              integrationInfo: IntegrationInfoResolver,
            },
            data: {
              profiles: Constants.AllAdminsRoles,
              breadcrumb: () => {
                return (
                  JSON.parse(self.localStorage.selectedSchool)?.txName ??
                  "Escola"
                );
              },
            },
            children: [
              {
                path: "",
                loadComponent: () =>
                  import("./pages/school/school-page.component").then(
                    (m) => m.SchoolPageComponent
                  ),
              },
              {
                path: "class",
                data: { breadcrumb: "Turmas" },
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/school-class-list/school-class-list-page.component"
                      ).then((m) => m.SchoolClassListPageComponent),
                  },
                  {
                    path: ":idSchoolClass/edit",
                    loadComponent: () =>
                      import(
                        "./pages/school-class/school-class-page.component"
                      ).then((m) => m.SchoolClassPageComponent),
                    data: { breadcrumb: "Editar Turma" },
                  },
                ],
              },
              {
                path: "professor",
                data: { breadcrumb: "Professores" },
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/professor-list/professor-list-page.component"
                      ).then((m) => m.ProfessorListPageComponent),
                  },
                ],
              },
              {
                path: "student",
                data: { breadcrumb: "Alunos" },
                canActivateChild: [AuthGuard],
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import(
                        "./pages/student-list/student-list-page.component"
                      ).then((m) => m.StudentListPageComponent),
                    data: {
                      profiles: [
                        Constants.PROFILES.LektoAdmin,
                        Constants.PROFILES.SchoolNetworkAdmin,
                        Constants.PROFILES.RegionalAdmin,
                        Constants.PROFILES.SchoolManager,
                      ],
                    },
                  },
                  {
                    path: ":idUser",
                    canActivate: [AuthGuard],
                    canActivateChild: [AuthGuard],
                    data: {
                      profiles: Constants.AllAdminsRoles,
                      breadcrumb: "Aluno",
                    },
                    children: [
                      {
                        path: "",
                        loadComponent: () =>
                          import(
                            "./pages/student-history/student-history.component"
                          ).then((m) => m.StudentHistoryComponent),
                        data: { breadcrumb: "Painel do aluno" },
                      },
                    ],
                  },
                ],
              },
              {
                path: "agenda",
                data: { breadcrumb: "Grade Horária" },
                children: [
                  {
                    path: "",
                    loadComponent: () =>
                      import("./pages/agenda/agenda.component").then(
                        (m) => m.AgendaComponent
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "reports",
        canActivateChild: [AuthGuard],
        data: {
          profiles: [
            Constants.PROFILES.SchoolNetworkAdmin,
            Constants.PROFILES.LektoAdmin,
            Constants.PROFILES.RegionalAdmin,
          ],
          breadcrumb: "Relatórios",
        },
        children: [
          {
            path: "",
            children: [
              { path: "", redirectTo: "/", pathMatch: "full" },
              {
                path: "licenses",
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                data: {
                  breadcrumb: "Relatório de Licenças",
                  profiles: [
                    Constants.PROFILES.SchoolNetworkAdmin,
                    Constants.PROFILES.LektoAdmin,
                    Constants.PROFILES.RegionalAdmin,
                  ],
                },
                children: [
                  {
                    path: "",
                    canActivate: [AuthGuard],
                    canActivateChild: [AuthGuard],
                    data: {
                      profiles: [
                        Constants.PROFILES.SchoolNetworkAdmin,
                        Constants.PROFILES.LektoAdmin,
                        Constants.PROFILES.RegionalAdmin,
                      ],
                    },
                    loadComponent: () =>
                      import(
                        "./pages/student-licenses/student-licenses.component"
                      ).then((m) => m.StudentLicensesComponent),
                  },
                  {
                    path: ":id",
                    canActivate: [AuthGuard],
                    canActivateChild: [AuthGuard],
                    data: {
                      breadcrumb: () => {
                        const obj = JSON.parse(
                          self.localStorage.selectedLicenseReport
                        );

                        if (obj) {
                          return `[${obj.competenceDescription}] ${obj.networkName}`;
                        }

                        return "Visualizar";
                      },
                      profiles: [
                        Constants.PROFILES.SchoolNetworkAdmin,
                        Constants.PROFILES.LektoAdmin,
                        Constants.PROFILES.RegionalAdmin,
                      ],
                    },
                    resolve: {
                      data: StudentLicenseReportResolver,
                    },
                    loadComponent: () =>
                      import(
                        "./pages/student-license-view/student-license-view.component"
                      ).then((m) => m.StudentLicenseViewComponent),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "hangfire",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      profiles: [Constants.PROFILES.LektoAdmin, Constants.PROFILES.SuperAdmin],
    },
    component: HangfireComponent,
  },
  { path: "404", component: NotFoundComponent },
  { path: "403", component: ForbiddenComponent },
  {
    path: "redirect/:state",
    loadComponent: () =>
      import("./pages/redirect/redirect.component").then(
        (m) => m.RedirectComponent
      ),
  },
  { path: "**", component: NotFoundComponent },
];
